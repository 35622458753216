import styled from "@emotion/styled"
import React from "react"
import tw from "tailwind.macro"

const Article = styled.article`
  ${tw`
    text-gray-700
  `}
`

const Header = styled.header`
  ${tw`
    mb-4
  `}
`
const Title = styled.h1`
  ${tw`
    text-4xl lg:text-5xl
  `}
`

const Date = styled.span`
  ${tw`
    text-sm
  `}
`

const Post = ({ post }) => (
  <Article>
    <Header>
      <Title>{post.title}</Title>
      <Date>{post.date}</Date>
    </Header>
    <section
      class="markdown"
      dangerouslySetInnerHTML={{ __html: post.content }}
    ></section>
  </Article>
)

export default Post
