import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import tw from "tailwind.macro"

const HeaderContainer = styled.header`
  ${tw`
    bg-purple-700
    flex items-center justify-center flex-wrap
  `}
`

const InnerContainer = styled.div`
  ${tw`
    text-white w-full px-12 py-2
    md:max-w-screen-sm md:px-2
    lg:max-w-screen-md
  `}
`

const Header = ({ siteTitle }) => (
  <HeaderContainer>
    <InnerContainer>
      <h1>
        <Link to="/">{siteTitle}</Link>
      </h1>
    </InnerContainer>
  </HeaderContainer>
)

export default Header
