import styled from "@emotion/styled"
import React from "react"
import tw from "tailwind.macro"

const FooterContainer = styled.footer`
  ${tw`
    bg-purple-900
    flex items-center justify-center flex-wrap
  `}
`

const InnerContainer = styled.div`
  ${tw`
    text-white w-full px-12 py-6
    md:max-w-screen-sm md:px-2
    lg:max-w-screen-md
  `}
`

const Copyright = styled.span`
  ${tw`
    text-xs
  `}
`

const Footer = () => (
  <FooterContainer>
    <InnerContainer>
      <Copyright>
        &copy; Copyright 2020, dctcheng. All Rights Reserved
      </Copyright>
    </InnerContainer>
  </FooterContainer>
)

export default Footer
