/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import tw from "tailwind.macro"
import Footer from "./footer"
import Header from "./header"

const LayoutContainer = styled.div`
  ${tw`
    min-h-screen
    flex flex-col
  `}
`

const MainContainer = styled.main`
  ${tw`
    w-full self-center px-12 py-6
    md:max-w-screen-sm md:px-6
    lg:max-w-screen-md
  `}
`

const FooterContainer = styled.div`
  ${tw`
    mt-auto
  `}
`

export const PureLayout = ({ children, title }) => (
  <LayoutContainer>
    <Header siteTitle={title} />
    <MainContainer>{children}</MainContainer>
    <FooterContainer>
      <Footer />
    </FooterContainer>
  </LayoutContainer>
)

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <PureLayout {...props} title={data.site.siteMetadata.title}></PureLayout>
  )
}

export default Layout
